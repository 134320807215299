<div class="container" #container>
  <div
    class="nav"
    [class.nav_active]="isActive$ | async"
    [prizmHint]="menuItem?.title"
    [prizmHintCanShow]="prizmIsTextOverflow(title)"
    (click)="navClick()"
  >
    <div class="nav__icon" [style.marginLeft.px]="deep > 0 ? 8 + 16 * deep : 0">
      <prizm-icons-full [name]="menuItem?.icon || 'folder'"></prizm-icons-full>
    </div>
    <span class="nav__title" #title>
      {{ menuItem?.title }}
    </span>

    <div class="nav__status">
      <div
        class="indicator {{
          menuItem?.indicatorStatus && !isExpanded ? 'indicator_' + menuItem?.indicatorStatus : ''
        }}"
      ></div>
    </div>

    <button class="nav__chevron" [class.nav__chevron_expanded]="isExpanded" (click)="toggle($event)">
      <!--      <prizm-icon class="icon" iconClass="arrows-chevron-right"></prizm-icon>-->
      <prizm-icons-full class="icon" name="angle-right"></prizm-icons-full>
    </button>
  </div>

  <div class="expandable" [@expand]="isExpanded ? 'expanded' : 'init'">
    <ng-container *ngFor="let item of menuItem?.children ?? []">
      <prizm-navigation-item-simple
        *ngIf="!item?.children"
        [data]="item"
        [deep]="deep + 1"
      ></prizm-navigation-item-simple>

      <prizm-navigation-item-expandable
        *ngIf="$any(item?.children?.length) > 0"
        [data]="item"
        [deep]="deep + 1"
      ></prizm-navigation-item-expandable>
    </ng-container>
  </div>
</div>

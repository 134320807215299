<div class="container">
  <div class="content">
    <span
      class="rows rows__count"
      *ngIf="!paginatorOptions?.noRowsSelector && !paginatorOptions?.noRowsSelectorLabel"
    >
      <ng-container *polymorphOutlet="textOnPage as title">
        {{ title }}
      </ng-container>
    </span>
    <div class="rows-selector" *ngIf="!paginatorOptions?.noRowsSelector">
      <prizm-input-layout [forceClear]="false" [outer]="true" label="" size="s">
        <prizm-input-select
          [items]="$any(this.rowsCountOptions?.length) > 0 ? this.rowsCountOptions : [rows]"
          [disabled]="disabled"
          [ngModel]="rows"
          [ngModelOptions]="{ standalone: true }"
          [nullContent]="$any(null)"
          (ngModelChange)="changeRows($event)"
        ></prizm-input-select>
      </prizm-input-layout>
    </div>
  </div>

  <div class="content">
    <span class="rows rows__show" *ngIf="!paginatorOptions?.noInfo" [hidden]="disabled">
      <span data-testid="paginator-i18n-lines-shown">
        {{ paginator$ | async | prizmPluck : ['linesShown'] }}
      </span>
      <span data-testid="paginator-lines-shown"> {{ (currentPage - 1) * rows + 1 }} </span>-
      <span data-testid="paginator-total-count">
        {{ currentPage * rows > $any(totalRecords) ? totalRecords : currentPage * rows }}
      </span>
      <span data-testid="paginator-i18n-from-text">
        {{ paginator$ | async | prizmPluck : ['fromText'] }}
      </span>
      <span data-testid="paginator-real-total-record">
        {{ realTotalRecord }}
      </span>
    </span>
    <div
      class="paginator paginator__finite"
      *ngIf="paginatorType === 'finite' && paginationGenerator() as pagingData"
    >
      <button
        class="control control_left"
        *ngIf="paginatorOptions.noToFirstPageBtn ?? true"
        [icon]="'chevrons-double-left'"
        [disabled]="disabled || currentPage === 1"
        (click)="toFirstPage()"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
      <button
        class="control control_left"
        *ngIf="!leftButtonLabel; else leftLabelBtn"
        [icon]="'angle-left'"
        [disabled]="disabled || currentPage === 1"
        (click)="decrease()"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
      <ng-template #leftLabelBtn>
        <button
          class="control control_left"
          *ngIf="leftButtonLabel"
          [icon]="'angle-left'"
          [disabled]="disabled || currentPage === 1"
          (click)="decrease()"
          prizmButton
          appearanceType="ghost"
          appearance="secondary"
          size="m"
        >
          {{ leftButtonLabel }}
        </button>
      </ng-template>

      <ng-container *ngIf="!paginatorOptions?.noPages">
        <ng-container *ngIf="pagingData.left">
          <button
            class="page"
            [disabled]="disabled"
            (click)="changePage(pagingData.left)"
            prizmButton
            appearanceType="ghost"
            appearance="secondary"
            size="m"
          >
            {{ pagingData.left }}
          </button>
          <div
            class="dots"
            *ngIf="pagingData.left + 1 !== pagingData.mid[0]"
            [class.dots_disabled]="disabled"
          >
            ...
          </div>
        </ng-container>

        <button
          class="page"
          *ngFor="let page of pagingData.mid"
          [disabled]="disabled"
          [pseudoPressed]="page === currentPage"
          (click)="changePage(page)"
          prizmButton
          appearanceType="ghost"
          appearance="secondary"
          size="m"
        >
          {{ page }}
        </button>

        <ng-container *ngIf="pagingData.right">
          <div
            class="dots"
            *ngIf="pagingData.right - 1 !== pagingData.mid[pagingData.mid.length - 1]"
            [class.dots_disabled]="disabled"
          >
            ...
          </div>
          <button
            class="page"
            [disabled]="disabled"
            (click)="changePage(pagingData.right)"
            prizmButton
            appearanceType="ghost"
            appearance="secondary"
            size="m"
          >
            {{ pagingData.right }}
          </button>
        </ng-container>
      </ng-container>

      <button
        class="control control_right"
        *ngIf="!rightButtonLabel; else rightLabelBtn"
        [icon]="'angle-right'"
        [disabled]="disabled || (currentPage === pagesCount ?? currentPage === 0)"
        (click)="increase()"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
      <ng-template #rightLabelBtn>
        <button
          class="control_right"
          [iconRight]="'angle-right'"
          [disabled]="disabled || (currentPage === pagesCount ?? currentPage === 0)"
          (click)="increase()"
          prizmButton
          appearanceType="ghost"
          appearance="secondary"
          size="m"
        >
          {{ rightButtonLabel }}
        </button>
      </ng-template>

      <button
        class="control control_right"
        *ngIf="paginatorOptions.noToLastPageBtn ?? true"
        [icon]="'chevrons-double-right'"
        [disabled]="disabled || (currentPage === pagesCount ?? currentPage === 0)"
        (click)="toLastPage()"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>
    </div>

    <div class="paginator paginator__infinite" *ngIf="paginatorType === 'infinite'">
      <button
        [disabled]="disabled || showMoreDisabled"
        (click)="increase()"
        prizmButton
        appearanceType="ghost"
        appearance="primary"
        size="m"
      >
        {{ moreButtonLabel }}
      </button>
    </div>
  </div>
</div>
